<template>
  <HourglassLoader :loading="loading">
    <section>
      <div class="d-flex justify-content-between align-items-center py-3">
        <div class="d-block">
          <h2 class="h4">Registered Users</h2>
          <p class="mb-0">Below are the registered users</p>
        </div>

        <div class="btn-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-outline-primary"
            @click="RefreshData"
          >
            Reload
          </button>
        </div>
      </div>
      <div v-if="data?.meta" class="table-settings mb-4">
        <div class="row align-items-center justify-content-between">
          <div class="col col-md-6 col-lg-3 col-xl-4">
            <form @submit.prevent="RefreshData">
              <div class="input-group">
                <span class="input-group-text"
                  ><span class="fas fa-search"></span
                ></span>
                <input
                  type="text"
                  class="form-control px-1"
                  placeholder="Find User"
                  aria-label="Find User"
                  v-model="data.meta.search"
                />
              </div>
            </form>
          </div>
          <div class="col-4 col-md-2 col-xl-1 pl-md-0">
            <div class="d-flex">
              <CountSelector
                class="me-2"
                v-model="data.meta.per_page"
                @updated="RefreshData"
              />
              <DataFilterSelector
                v-model="type"
                :filters="filters"
                @updated="RefreshData"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="card card-body border-light shadow-sm table-wrapper table-responsive pt-0"
      >
        <table class="table table-hover min-h-400">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Username</th>
              <th>Email</th>
              <th>Is Banned</th>
              <th>Clearance</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="data">
            <UserRow v-for="(dt, index) in data.users" :key="index" :dt="dt" />
          </tbody>
        </table>
        <Pagination
          v-if="data"
          v-model="data.meta.page"
          :currentPage="data.meta.page"
          :totalRecord="data.meta.count"
          :dataCount="data.meta.per_page"
          @updated="RefreshData"
        />
      </div>
    </section>
  </HourglassLoader>
</template>

<script lang="ts">
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import Pagination from "@/components/mini/Pagination.vue";
import CountSelector from "@/components/mini/CountSelector.vue";
import { onBeforeMount, ref, defineComponent } from "vue";
import { apiGet } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import UserRow from "@/views/users/constituent/UserRow.vue";
import DataFilterSelector from "@/components/selector/DataFilterSelector.vue";

export default defineComponent({
  name: "RegisteredForMasspayoutID",
  components: {
    DataFilterSelector,
    UserRow,
    HourglassLoader,
    Pagination,
    CountSelector,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);
    const filters = {
      0: "All",
      100: "Clearance 100",
      3: "Clearance 3",
      1: "Clearance 1",
    };
    const type = ref(undefined);

    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/user-account`,
          Object.assign((data.value as any)?.meta ?? {}, { type: type.value })
        );
        data.value = response.data.data;
        Toast.fire({
          icon: "success",
          title: "Users Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      route,
      data,
      loading,
      filters,
      type,
    };
  },
});
</script>

<style scoped></style>
