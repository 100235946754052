
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import Pagination from "@/components/mini/Pagination.vue";
import CountSelector from "@/components/mini/CountSelector.vue";
import { onBeforeMount, ref, defineComponent } from "vue";
import { apiGet } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import UserRow from "@/views/users/constituent/UserRow.vue";
import DataFilterSelector from "@/components/selector/DataFilterSelector.vue";

export default defineComponent({
  name: "RegisteredForMasspayoutID",
  components: {
    DataFilterSelector,
    UserRow,
    HourglassLoader,
    Pagination,
    CountSelector,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);
    const filters = {
      0: "All",
      100: "Clearance 100",
      3: "Clearance 3",
      1: "Clearance 1",
    };
    const type = ref(undefined);

    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/user-account`,
          Object.assign((data.value as any)?.meta ?? {}, { type: type.value })
        );
        data.value = response.data.data;
        Toast.fire({
          icon: "success",
          title: "Users Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      route,
      data,
      loading,
      filters,
      type,
    };
  },
});
